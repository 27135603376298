/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Account } from '../../../../components/accounts/listAccounts/types';
import { UpdateAccountAction, UpdateAccountActionTypes } from './action';

export interface UpdateAccountState {
  error?: Error;
  loading?: boolean;
  account?: Account;
}

export const initialUpdateAccountState: UpdateAccountState = {
  loading: false,
};

export default function updateAccountReducer(state = initialUpdateAccountState, action: UpdateAccountAction) {
  switch (action.type) {
    case UpdateAccountActionTypes.UPDATE_ACCOUNT_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case UpdateAccountActionTypes.UPDATE_ACCOUNT_SUCCESS:
      return update(state, {
        loading: { $set: false },
        account: { $set: action.account },
      });

    case UpdateAccountActionTypes.UPDATE_ACCOUNT_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    default:
      return state;
  }
}
