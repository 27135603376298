/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { getUserRole } from '../../../../components/common/helpers';
import { UserRole } from '../../../../components/common/roles';
import {
  ListAccounts,
  ListAccountsAction,
  ListAccountsActionTypes,
  listAccountsFailed,
  listAccountsSucceeded,
  ListAccountsSuccess,
} from './actions';
import { listAccountsQuery } from './queries';

export default function listAccountsEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListAccountsAction) => action.type === ListAccountsActionTypes.LIST_ACCOUNTS_REQUEST),
    debounceTime(500),
    switchMap((action: ListAccounts) =>
      listAccounts(action, state$.value.accounts.size, state$.value.login?.currentUser).then().catch((error) =>
        listAccountsFailed(error),
      ),
    ),
  );
}

export async function listAccounts(
  { currentPage, searchString, filter }: ListAccounts,
  size: number,
  currentUser: any,
): Promise<ListAccountsSuccess> {
  const graphQLClient = await getClient();

  const userRole = getUserRole(currentUser);

  switch (userRole) {
    case UserRole.USER: {
      filter.userId = currentUser.sub;

      break;
    }
  }

  const {
    data: {
      listAccounts: { total, accounts, totalAmount },
    },
  } = await graphQLClient.query({
    query: listAccountsQuery,
    variables: {
      input: {
        filter,
        from: (currentPage - 1) * size,
        query: searchString,
        size,
      },
    },
  });

  const filteredAccounts = accounts.filter((account) => typeof account === 'object' && account);

  return listAccountsSucceeded(total, filteredAccounts, totalAmount);
}
