/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Account } from './../types';
import { DEFAULT_ACCOUNT_TYPE, ListAccountsAction, ListAccountsActionTypes, ListAccountsFilter } from './actions';

export interface AccountsState {
  currentPage: number;
  error?: Error;
  loading?: boolean;
  accounts: Account[];
  filter?: ListAccountsFilter;
  searchString: string;
  selectedAccounts: any[];
  size: number;
  total: number;
  totalBalance: number;
}

const size: number = 20;

export const initialAccountsState: AccountsState = {
  accounts: [],
  currentPage: 1,
  filter: DEFAULT_ACCOUNT_TYPE,
  loading: false,
  searchString: '',
  selectedAccounts: [],
  size,
  total: 0,
  totalBalance: 0,
};

export default function accountsReducer(state = initialAccountsState, action: ListAccountsAction) {
  switch (action.type) {
    case ListAccountsActionTypes.LIST_ACCOUNTS_REQUEST:
      const { currentPage, searchString, filter } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        filter: { $set: filter },
        searchString: { $set: searchString },
      });

    case ListAccountsActionTypes.LIST_ACCOUNTS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        accounts: { $set: action.accounts },
        total: { $set: action.total },
        totalBalance: { $set: action.totalAmount || 0 },
      });

    case ListAccountsActionTypes.LIST_ACCOUNTS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListAccountsActionTypes.SELECT_ACCOUNT:
      return update(state, {
        selectedAccounts: { $set: [action.account] },
      });

    case ListAccountsActionTypes.RESET_SELECTED_ACCOUNT:
      return update(state, {
        selectedAccounts: { $set: initialAccountsState.selectedAccounts },
      });
    case ListAccountsActionTypes.RESET_ERROR:
      return update(state, { $unset: ['error'] });

    case ListAccountsActionTypes.RESET_STATE:
      return update(state, {
        currentPage: { $set: initialAccountsState.currentPage },
        accounts: { $set: initialAccountsState.accounts },
        error: { $set: initialAccountsState.error },
        loading: { $set: initialAccountsState.loading },
        filter: { $set: initialAccountsState.filter },
        searchString: { $set: initialAccountsState.searchString },
        selectedAccounts: { $set: initialAccountsState.selectedAccounts },
        size: { $set: initialAccountsState.size },
        total: { $set: initialAccountsState.total },
        totalBalance: { $set: initialAccountsState.totalBalance },
      });

    default:
      return state;
  }
}
