/* External dependencies */
import gql from 'graphql-tag';

export const updateAccountMutation = gql`
  mutation updateAccountForAdminPanel($input: UpdateAccountInput!) {
    updateAccountForAdminPanel(input: $input) {
      id
    }
  }
`;
