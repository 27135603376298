/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { Account } from '../../../../components/accounts/listAccounts/types';
import {
  UpdateAccountAction,
  UpdateAccountActionTypes,
  updateAccountError,
  UpdateAccountInput,
  UpdateAccountRequest,
  updateAccountSuccess,
} from './action';
import { updateAccountMutation } from './mutation';

export default function updateAccountEpic(action$) {
  return action$.pipe(
    filter((action: UpdateAccountAction) => action.type === UpdateAccountActionTypes.UPDATE_ACCOUNT_REQUEST),
    switchMap((action: UpdateAccountRequest) =>
      updateAccount(action.payload).then(updateAccountSuccess).catch(updateAccountError),
    ),
  );
}

export async function updateAccount(input: UpdateAccountInput): Promise<Account> {
  const graphQLClient = await getClient();

  const {
    data: { updateAccount },
  } = await graphQLClient.mutate({
    mutation: updateAccountMutation,
    variables: {
      input: {
        id: input.id,
        status: input.status && {
          statusType: input.status.statusType,
        },
      },
    },
  });

  return updateAccount;
}
