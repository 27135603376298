/* Local dependencies */
import { Account } from '../../../../components/accounts/listAccounts/types';
import { UserStatus } from '../../../../components/users/userTypes';

export enum UpdateAccountActionTypes {
  UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR',
}

export interface AccountStatusInput {
  statusReason?: string;
  statusType?: UserStatus;
}

export interface UpdateAccountInput {
  id: string;
  name?: string;
  isDefault?: boolean;
  status?: AccountStatusInput;
}

export interface UpdateAccountRequest {
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_REQUEST;
  payload: UpdateAccountInput;
}

export interface UpdateAccountSuccess {
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_SUCCESS;
  account: Account;
}

export interface UpdateAccountError {
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_ERROR;
  error: Error;
}

export type UpdateAccountAction = UpdateAccountRequest | UpdateAccountSuccess | UpdateAccountError;

export const updateAccountRequest = (input: UpdateAccountInput): UpdateAccountRequest => ({
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_REQUEST,
  payload: input,
});

export const updateAccountSuccess = (account: Account): UpdateAccountSuccess => ({
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_SUCCESS,
  account,
});

export const updateAccountError = (error: Error): UpdateAccountError => ({
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_ERROR,
  error,
});
